<template>
  <div>
    <v-row>
      <v-col lg="6">
        <cultive :isAdmin="true"  @rowSelected="rowSelected($event)"  :height="100"> >
        </cultive>        
      </v-col>
      <v-col lg="6">
        <variety :isAdmin="true"  :DedID="dedID" :height="200"> >
        </variety> 
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Variety from "@/views/TechnicalAssistance/Variety/Variety";
import Cultive from "@/views/TechnicalAssistance/Variety/Cultive";

export default {
  components: {
        Cultive
        , Variety
      //
  },

   data() {
    return {
      parentID: 0,
      dedID:0,
     
    };
  },

  methods: {
    rowSelected(rows) {
     this.dedID=rows[0].DedValue;
         },
   
  },
};
</script>
